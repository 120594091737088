import { registerLocaleData } from '@angular/common'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import localeBeNl from '@angular/common/locales/nl-BE'
import { NgModule } from '@angular/core'
import { MatLuxonDateModule } from '@angular/material-luxon-adapter'
import { MatDialogModule } from '@angular/material/dialog'
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule } from '@angular/router'
import { SharedAuthModule } from '@linkx/shared-auth'
import { MessageBarComponent } from '@linkx/shared-components-ui'
import { provideMetadataLoading } from '@linkx/shared-data-access'
import { APP_CONFIG, SharedDateUtilsModule } from '@linkx/shared-utils'
import { SharedWireframeModule } from '@linkx/shared-wireframe'
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { NgxsModule } from '@ngxs/store'
import { ToastrModule } from 'ngx-toastr'
import { environment } from '../../environments/environment'
import { AppComponent } from './app.component'
import { appRoutes } from './app.routes'

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, './shared-assets/i18n/', '.json')
}

const defaultLanguage = 'nl'
registerLocaleData(localeBeNl)

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking', useHash: true }),
        SharedAuthModule.forRoot({
            awsCognito: environment.awsCognito,
            defaultLanguage
        }),
        SharedWireframeModule,
        SharedDateUtilsModule,
        MatLuxonDateModule,
        HttpClientModule,
        TranslateModule.forRoot({
            defaultLanguage,
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        NgxsModule.forRoot(),
        ToastrModule.forRoot({
            positionClass: 'toast-top-right'
        }),
        MatDialogModule,
        MessageBarComponent
    ],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'outline'
            }
        },
        {
            provide: APP_CONFIG,
            useValue: environment
        },
        provideMetadataLoading()
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    public constructor(translate: TranslateService) {
        translate.setDefaultLang(defaultLanguage)
        translate.use(defaultLanguage)
    }
}
