<div class="social-login-buttons">
    <button (click)="signInGoogle()" class="social-login-button google-login-button">
        <i class="fa-brands fa-google"></i>
        <span>{{ 'auth.sign-in-google' | translate }}</span>
    </button>
    <button (click)="signInFacebook()" class="social-login-button facebook-login-button">
        <i class="fa-brands fa-facebook"></i>
        <span>{{ 'auth.sign-in-facebook' | translate }}</span>
    </button>
</div>
