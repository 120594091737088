import { Route } from '@angular/router'
import { linkxAdminGuard, WelcomeComponent } from '@linkx/shared-auth'

export const appRoutes: Route[] = [
    {
        path: 'bookings',
        loadChildren: () => import('@linkx/backoffice-booking-feature').then((m) => m.BackofficeBookingFeatureModule),
        canActivate: [linkxAdminGuard]
    },
    {
        path: 'employees',
        loadChildren: () => import('@linkx/backoffice-employee-feature').then((m) => m.BackofficeEmployeeFeatureModule),
        canActivate: [linkxAdminGuard]
    },
    {
        path: 'employers',
        loadChildren: () => import('@linkx/backoffice-employer-feature').then((m) => m.SharedEmployerFeatureModule),
        canActivate: [linkxAdminGuard]
    },
    {
        path: 'welcome',
        component: WelcomeComponent,
        canActivate: [linkxAdminGuard]
    },
    {
        path: '**',
        redirectTo: 'welcome'
    }
]
