import { animate, style, transition, trigger } from '@angular/animations'
import { Component, inject, OnInit } from '@angular/core'
import { LinkxAuthFacade, LinkxUserInfo, userIsAdmin } from '@linkx/shared-auth'
import { Severity } from '@linkx/shared-components-ui'
import { mixinHandleSubscriptions } from '@linkx/shared-utils'
import { NavigationItem } from '@linkx/shared-wireframe'
import { map, Observable, of } from 'rxjs'

@Component({
    selector: 'linkx-root',
    templateUrl: './app.component.html',
    animations: [
        trigger('fade', [
            transition(':enter', [style({ opacity: 0 }), animate(300, style({ opacity: 1 }))]),
            transition(':leave', [style({ opacity: 1 }), animate(300, style({ opacity: 0 }))])
        ])
    ]
})
export class AppComponent extends mixinHandleSubscriptions() implements OnInit {
    private auth: LinkxAuthFacade = inject(LinkxAuthFacade)

    public currentUserProfile$: Observable<LinkxUserInfo | null> = this.auth.getUserProfile()
    public isAdmin$: Observable<boolean> = this.currentUserProfile$.pipe(
        map((userProfileOrNull) => !!userProfileOrNull && userIsAdmin(userProfileOrNull))
    )
    public navigationItems$: Observable<Array<NavigationItem>> = of([
        { icon: 'list_alt', label: 'sidenav.requests', fullRouterPath: 'bookings' },
        { icon: 'person', label: 'sidenav.employees', fullRouterPath: 'employees' },
        { icon: 'groups', label: 'sidenav.employers', fullRouterPath: 'employers' }
    ])
    public showAdminOnlyMessage = false

    public Severity: typeof Severity = Severity

    public ngOnInit(): void {
        this.checkAdminOnlyMessage()
        this.stopOnDestroy(this.isAdmin$).subscribe(async (isAdmin) => {
            if (!isAdmin) {
                // Force a sign-out when the user is not an admin. Only admins are allowed to access the
                // backoffice application.
                // When signing out, a full page refresh takes place. Because of the page refresh we just can't
                // set properties or state to communicate to the user that only admins are allowed. This also means
                // that a toast message will disappear once page refresh kicks in. To ensure that we can display the
                // message to the user, we set this key in the session storage. When loading the application (again)
                // the session storage is checked whether this key has the value of 1 to display the message
                // and removed after the check.
                sessionStorage.setItem('admin-only', (1).toString())
                await this.auth.signOut()
            }
        })
    }

    private checkAdminOnlyMessage(): void {
        const adminOnly = Number(sessionStorage.getItem('admin-only'))
        if (adminOnly === 1) {
            this.showAdminOnlyMessage = true

            setTimeout(() => {
                this.showAdminOnlyMessage = false
            }, 10000)
        }
        sessionStorage.removeItem('admin-only')
    }
}
