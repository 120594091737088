<amplify-authenticator [hideSignUp]="true">
    <ng-template amplifySlot="authenticated">
        <linkx-app-wireframe
            *ngIf="isAdmin$ | async"
            [navigationItems]="navigationItems$ | async"></linkx-app-wireframe>
    </ng-template>

    <ng-template amplifySlot="sign-in">
        <div class="sign-in-wrapper">
            <div class="sign-in-form">
                <h1>{{ 'auth.sign-in-to-backoffice' | translate }}</h1>
                <linkx-sign-in></linkx-sign-in>
                <linkx-message-bar
                    *ngIf="showAdminOnlyMessage"
                    @fade
                    [severity]="Severity.warning"
                    [message]="'auth.only-admin-allowed' | translate"></linkx-message-bar>
            </div>

            <div class="sign-in-background">
                <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" height="100%" width="100%">
                    <g>
                        <rect width="100%" height="100%" x="0" y="0" fill="rgba(21, 67, 96, 1)"></rect>
                        <path
                            d="M0,501.319C103.406,509.715,222.982,537.695,303.553,472.338C384.373,406.779,356.25,276.995,395.405,180.575C432.222,89.912,526.231,22.214,526.078,-75.639C525.92,-176.675,466.424,-270.864,394.587,-341.911C325.549,-410.19,231.59,-447.17,135.603,-461.822C47.345,-475.294,-35.11,-432.709,-123.62,-421.012C-232.523,-406.62,-358.992,-454.122,-444.5,-385.162C-530.14,-316.096,-540.749,-188.569,-547.971,-78.786C-554.82,25.323,-534.276,129.578,-484.39,221.213C-436.622,308.957,-359.929,375.477,-273.444,425.488C-189.868,473.817,-96.226,493.506,0,501.319"
                            fill="#11364d"></path>
                        <path
                            d="M1440 1152.8020000000001C1550.94 1148.33 1649.904 1091.978 1742.878 1031.287 1835.29 970.962 1914.372 896.674 1974.316 804.014 2042.034 699.337 2116.88 588.142 2110.094 463.655 2103.12 335.709 2033.725 213.53199999999998 1937.5149999999999 128.901 1846.524 48.86099999999999 1719.194 37.02800000000002 1600.032 14.980000000000018 1492.117-4.986999999999966 1385.241-14.423000000000002 1277.564 6.793000000000006 1161.533 29.654999999999973 1047.638 65.45400000000001 957.1859999999999 141.64 857.066 225.97000000000003 745.473 328.589 740.224 459.387 734.988 589.874 860.841 682.3199999999999 931.071 792.42 990.567 885.693 1030.723 992.421 1121.011 1056.356 1213.541 1121.8780000000002 1326.712 1157.3690000000001 1440 1152.8020000000001"
                            fill="#195073"></path>
                    </g>
                </svg>
            </div>
        </div>
    </ng-template>
</amplify-authenticator>
