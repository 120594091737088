import { ChangeDetectionStrategy, Component, inject } from '@angular/core'
import { LinkxAuthFacade } from '../linkx-auth.facade'
import { mixinRelativeNavigation } from '@linkx/shared-utils'

@Component({
    selector: 'linkx-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignInComponent extends mixinRelativeNavigation() {
    private auth: LinkxAuthFacade = inject(LinkxAuthFacade)
    public async signInFacebook(): Promise<void> {
        await this.auth.signInFacebook()

        await this.relativeNavigation(['../welcome'])
    }

    public async signInGoogle(): Promise<void> {
        await this.auth.signInGoogle()

        await this.relativeNavigation(['../welcome'])
    }
}
